import { useState, useEffect } from 'react';
import './index.css';
import { BigNumber, ethers } from 'ethers';
import backgroundImage from './assets/images/BG-+-barattolo.png'
import telegram from './assets/images/telegram.svg'
import bsc from './assets/images/etherscan.svg'
import twitter from './assets/images/twitter.svg'
import machine from './assets/images/machine.png'
import logo from './assets/images/pop-corn-logo2.png'
import whitepaper from './assets/docs/wp.pdf'
import audit from './assets/docs/audit.pdf'
import useInterval from './utils/UseInterval';
import Theme from './Theme';
import copy from 'copy-to-clipboard';
import CountUp from 'react-countup';
import ABI from './ABI';
import imgStandard from './assets/images/immagine-standard-senza-sfondo.png';
import logoAzzurro from './assets/images/logoazzurro.png';
import manonft from './assets/images/manonft.png';

import App from './App';
import {Routes,Route} from 'react-router-dom';

import aowayLogo from './assets/images/arrow.png';
import aowayLogoText from './assets/images/aoway.png';

export default function Dapp() {
	const addressContract = '0x070087F9604c9F1b15d917D415C0fc223d7e2249';
	const { ethereum } = (window as any);
	const provider = new ethers.providers.Web3Provider(ethereum);
	const contract = new ethers.Contract(addressContract, ABI, provider);
	const signer = provider.getSigner();
  const [isConnected, setIsConnected] = useState(false);

	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<String>("");
	const [message, setMessage] = useState<String>("");

	const [privateSaleLive, setPrivateSaleLive] = useState<Boolean>(false);
	const [publicSaleLive, setPublicSaleLive] = useState<Boolean>(false);
	const [freeMint, setFreeMint] = useState<Boolean>(false);
	const [whitelisted, setWhitelisted] = useState<Boolean>(false);
	const [minted, setMinted] = useState<BigNumber>(BigNumber.from(0));
	const [isFirstCollection, setIsFirstCollection] = useState<Boolean>(); 
	const [isMintPage, setIsMintPage] = useState<Boolean>(); 
	const [isSidebarOpen, setIsSidebarOpen] = useState<Boolean>(); 
	const [price, setPrice] = useState<BigNumber>(BigNumber.from(80));
	const [address, setAddress] = useState<String>();

	const nftlist = ["12","42","542"];
	const [count, setCount] = useState(BigNumber.from(1));

	async function onMint(){
		console.log('Mint for: ' + price.toString())
		await provider.send("eth_requestAccounts", []);
		const signer = provider.getSigner()
		const address = await signer.getAddress();
		console.log("Account:", address);

		const balance = await provider.getBalance(address);
		console.log(ethers.utils.formatEther(balance));
		if (balance.lt(price.mul(count))) {
				alert("Insufficent balance");
				return
		}

		const connection = contract.connect(signer);
		console.log("Connected")
		// try {
			let nftTx
			nftTx = await connection.mint(count, { value: price.mul(count) })
			console.log("TX " + nftTx.hash)
			showMessage("Mint transaction sended")
			await nftTx.wait();
		// } catch (e) {
		// 	if ((e as any).error !== undefined) {
		// 			showError((e as any).error.message)
		// 	} else {
		// 			showError((e as any).message)
		// 	}
		// }
	}

  async function onConnect() {
    if ((window.innerWidth <= 1200 && !ethereum) || !ethereum) {
      const queryString = window.location.search;
      try {
        window.location.replace("https://metamask.app.link/dapp/aoway.io");
      } catch (e) {
        console.log(e)
      }
      return
    }
    if (provider == null) { return }
    console.log('Connect')
    await provider.send("eth_requestAccounts", []);
    const signer = provider.getSigner()
    const address = await signer.getAddress()
    console.log("Account:", address);
    // let chainId = await ethereum.request({ method: 'eth_chainId' })
    // console.log('Connected to chain:' + chainId)

    const balance = await provider.getBalance(address)
    console.log(ethers.utils.formatEther(balance))
    setAddress(address)
    setIsConnected(true)
  }

	useEffect(() => {
			setIsMintPage(true);
	}, []);

	function showMessage(title: String) {
		setMessage(title);
		setTimeout(() => {
				setMessage("");
		}, 5000);
	}

	function showError(title: String) {
		setError(title);
		setTimeout(() => {
				setError("");
		}, 5000);
	}



  return (

    <div className="container-fluid d-flex position-relative align-items-center justify-content-center">
    <div className='banner-logo' style={{backgroundImage:"url('"+logoAzzurro+"')", zIndex: "-1"}}></div>
    <div className='banner-mano' style={{backgroundImage:"url('"+manonft+"')", zIndex: "-1"}}></div>

    {
     isConnected && <span className="hamburger-menu" onClick={()=>setIsSidebarOpen(!isSidebarOpen)}>{isSidebarOpen ? "x" : "="}</span>
    }
      
    {
      isConnected && isSidebarOpen ?
      <div className={`side-menu ${window.innerWidth > 1000 ? 'position-relative': 'position-fixed'}`}>
      <ul className="list-links">
        <li>
        <div className="sidebar-logo mb-5">
          <img style={{
						width: "50px"
					}} src={aowayLogo}/>
        </div>
        </li>
        <li onClick={()=>setIsMintPage(!isMintPage)} className={`menu-item ${isMintPage == true ? 'active' : ''}`}>MINT</li>
        {/* <li onClick={()=>setIsMintPage(!isMintPage)} className={`menu-item ${isMintPage == false ? 'active': ''}`}>MY COLLECTION</li> */}
      </ul>
  </div> : ""
    }
        
        { isMintPage ? 
          <div className="main container d-flex align-items-center justify-content-center flex-column text-center">
            
              <div className="">
                <img src={aowayLogoText} alt="" className="logo-main mb-5" />
                <h1 className="main-title mb-5">FIRST MINT IS LIVE</h1>
                {
                  isConnected ? <>
                  <div className="input-components mb-4">
                </div>
                <button className="btn-mint mb-3 mt-2" onClick={()=>onMint()}>MINT NOW</button>
                </> : <button className=" btn-mint mb-3 mt-2" onClick={()=>onConnect()}>Connect Wallet</button>
                }
            </div>
          </div>

        :

        <div className="main container align-items-start flex-column">
            <h2 id="second-title" className="main-title title-spacing ml-3">My Collection</h2>
              <div className="switch-collection mt-2 ml-3">
                <button onClick={()=>setIsFirstCollection(!isFirstCollection)} className={`switch-tab-left ${isFirstCollection == true ? 'switch-active' : ''}`}>Collection 1</button>
                <button onClick={()=>setIsFirstCollection(!isFirstCollection)} className={`switch-tab-right ${isFirstCollection == false ? 'switch-active' : ''}`}>Collection 2</button>
              </div>

              {
                isFirstCollection ?
              
              <div className="row text-center">
                  {
                    nftlist.map(id => 
                      <div className="col-lg-3 col-md-6">
                      <div className="card-nft mt-5">
                        <img src={imgStandard} />
                      </div>
                      <h6 className="nft-name text-center mt-2">Aoway Card</h6>
                      <p className="nft-serial text-center">No. {id}</p>
                    </div> 
                    )
                  }


                <div className="col-lg-3 col-md-6">
                  <div className="card-nft mt-5">
                    <img src="" />
                  </div>
                  <h6 className="nft-name text-center mt-2">Aoway Card</h6>
                  <p className="nft-serial text-center">No. 0002</p>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="card-nft mt-5">
                    <img src="" />
                  </div>
                  <h6 className="nft-name text-center mt-2">Aoway Card</h6>
                  <p className="nft-serial text-center">No. 0003</p>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="card-nft mt-5">
                    <img src="" />
                  </div>
                  <h6 className="nft-name text-center mt-2">Aoway Card</h6>
                  <p className="nft-serial text-center">No. 0004</p>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="card-nft mt-5">
                    <img src="" />
                  </div>
                  <h6 className="nft-name text-center mt-2">Aoway Card</h6>
                  <p className="nft-serial text-center">No. 0005</p>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="card-nft mt-5">
                    <img src="" />
                  </div>
                  <h6 className="nft-name text-center mt-2">Aoway Card</h6>
                  <p className="nft-serial text-center">No. 0006</p>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="card-nft mt-5">
                    <img src="" />
                  </div>
                  <h6 className="nft-name text-center mt-2">Aoway Card</h6>
                  <p className="nft-serial text-center">No. 0007</p>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="card-nft mt-5">
                    <img src="" />
                  </div>
                  <h6 className="nft-name text-center mt-2">Aoway Card</h6>
                  <p className="nft-serial text-center">No. 0008</p>
                </div>
              </div>

              :

              <div className="row text-center">
                <div className="col-lg-3 col-md-6">
                  <div className="card-nft mt-5">
                    <img src="" />
                  </div>
                  <h6 className="nft-name text-center mt-2">Aoway Card</h6>
                  <p className="nft-serial text-center">No. 0009</p>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="card-nft mt-5">
                    <img src="" />
                  </div>
                  <h6 className="nft-name text-center mt-2">Aoway Card</h6>
                  <p className="nft-serial text-center">No. 0010</p>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="card-nft mt-5">
                    <img src="" />
                  </div>
                  <h6 className="nft-name text-center mt-2">Aoway Card</h6>
                  <p className="nft-serial text-center">No. 0011</p>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="card-nft mt-5">
                    <img src="" />
                  </div>
                  <h6 className="nft-name text-center mt-2">Aoway Card</h6>
                  <p className="nft-serial text-center">No. 0012</p>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="card-nft mt-5">
                    <img src="" />
                  </div>
                  <h6 className="nft-name text-center mt-2">Aoway Card</h6>
                  <p className="nft-serial text-center">No. 0013</p>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="card-nft mt-5">
                    <img src="" />
                  </div>
                  <h6 className="nft-name text-center mt-2">Aoway Card</h6>
                  <p className="nft-serial text-center">No. 0014</p>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="card-nft mt-5">
                    <img src="" />
                  </div>
                  <h6 className="nft-name text-center mt-2">Aoway Card</h6>
                  <p className="nft-serial text-center">No. 0015</p>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="card-nft mt-5">
                    <img src="" />
                  </div>
                  <h6 className="nft-name text-center mt-2">Aoway Card</h6>
                  <p className="nft-serial text-center">No. 0016</p>
                </div>
              </div>
              }
              </div>
              }
          </div>
  );
}
