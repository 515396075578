import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Dapp from './Dapp';
import reportWebVitals from './reportWebVitals';
import * as bootstrap from 'react-bootstrap';
import {BrowserRouter,Routes,Route } from 'react-router-dom';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(

    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="dapp" element={<Dapp />} />
      </Routes>
  </BrowserRouter>
   
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

