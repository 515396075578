import { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import './index.css';

import Dapp from './Dapp'
import imgStandard from './assets/images/immagine-standard-senza-sfondo.png';
import imgAndrea from './assets/images/andrea.png';
import imgPaolo from './assets/images/paolo.png';
import imgFabio from './assets/images/fabio.png';
import sfondo from './assets/images/sfondo.png';
import sfondoHome from './assets/images/sfondo.jpg';
import wp from './assets/docs/wp.pdf';


import logofooter from './assets/images/logofooter.png';  
import Accordion from 'react-bootstrap/Accordion';

import logoAzzurro from './assets/images/logoazzurro.png';
import aowayLogoText from './assets/images/aoway.png';
import manonft from './assets/images/manonft.png';
import aowayLogo from './assets/images/arrow.png';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function App() {
  const nftlist = ['0001', '0002', '0003', '0004', '0005', '0006', '0007', '0008', '0017', '0039', '0012', '0139',];
  const nftlist2 = ['0043', '0012', '0065', '0075', '0092', '0013', '0018', '0038', '0069'];
  const [isFirstCollection, setIsFirstCollection] = useState(true)
  var smooth = [$('a.smooth'), 100, 750];
  const [show, setShow] = useState(false);
  const [showProfile, setShowprofile] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseProfile = () => setShowprofile(false);
  const handleShowProfile = () => setShowprofile(true);

  /*  smooth[0].click(function() {
      $('html, body').animate({
          scrollTop: $('[id="' + $.attr(this, 'href').substr(1) + '"]').offset().top -smooth[1]
      }, smooth[2]);
      return false;
    });
    */

  return (

    <div className="container-fluid">
      <nav className="navbar navbar-expand-lg fixed-top navbar-light bg-light">
        <a className="navbar-brand" href="#">
          <img src={aowayLogoText} alt="" />
        </a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">

            <li className="nav-item">
              <a className="nav-link" href={wp} rel="noreferrer">Whitepaper</a>
            </li>
            <li className="nav-item">
              &nbsp;&nbsp;
            </li>

            <li className="nav-item">
              <div className='nav-link' >
                <a target="_blank" href='https://discord.gg/Qezt5fyjs7' style={{ "color": "#000" }}><img src="https://assets-global.website-files.com/6257adef93867e50d84d30e2/636e0a6ac3c481f273141736_icon_clyde_black_RGB.png" /></a> &nbsp;
                <a target="_blank" href="https://twitter.com/AowayNft/" style={{ "color": "#000" }}><i className="fa fa-twitter" aria-hidden="true"></i></a> &nbsp;
              </div>
            </li>
          </ul>

        </div>
      </nav>

      <div className="container-fluid banner-container" style={{ backgroundImage: "url('" + sfondoHome + "')",
    backgroundSize: "cover" }}>
        <div className='banner-logo' style={{ backgroundImage: "url('" + logoAzzurro + "')" }}></div>
        {/*<div className='banner-mano' style={{ backgroundImage: "url('" + manonft + "')" }}></div>*/}
        <div className="container" >
          <div className="banner-content col-md-5">
            <h1 className="banner-title" style={{color: "#fff"}}>Real life benefits for NFT holders</h1>
            <p className="banner-parag">Blockchain project developed on simplicity, innovation, savings and investment. The NFTs are finally matching you daily needs. </p>
            <div className="d-flex flex-column justify-content-start">
							<a href="/dapp" className="color-white text-decoration-none text-center btn-mint mb-3 mt-2 ml-0"><b>MINT NOW</b></a>  
							<a href={wp} className="color-white text-decoration-none text-center btn-mint mb-3 mt-2 ml-0">Whitepaper</a>  
            </div>
          </div>
        </div>
      </div>



      <div className='row' style={{ "marginTop": "80px" }}>
        <div className='col-md-1 col-1'></div>
        <div className='col-md-10 col-10'>
          <h1 className="banner-title color-lightblue">Join Whitelist</h1>
          <p className="banner-parag mt-3">Mint date is coming soon! Grab your whitelist spot
            <a href="https://discord.gg/Qezt5fyjs7" style={{"display" : "block", "marginTop" : "-20px"}} target='_blank'><button className="btn-mint mb-3 mt-5 ml-0" >Join Discord</button></a>
          </p>

        </div>
      </div>

      <div className="container text-center">

        {
          isFirstCollection ?

            <div className="row text-left">
              {
                nftlist.map(id =>
                  <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                    <div className="card-nft mt-5">
                      <img src={imgStandard} />
                    </div>
                    <h6 className="nft-name text-center mt-2">Aoway Card</h6>
                    <p className="nft-serial text-center">No. {id}</p>
                  </div>
                )
              }

            </div>

            :

            <div className="row text-center">
              {
                nftlist2.map(id =>
                  <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                    <div className="card-nft mt-5">
                      <img src={imgStandard} />
                    </div>
                    <h6 className="nft-name text-center mt-2">Aoway Card</h6>
                    <p className="nft-serial text-center">No. {id}</p>
                  </div>
                )
              }

            </div>
        }
  <br></br>
      </div>
          <div className="row sectionProfile" style={{ backgroundImage: `url(${sfondo})` }}>
          <div className="col-lg-4">
              <div className="mindmap-card">
              <div style={{ "textAlign": "center" }}>

                <img className='logoProfilo' src={imgFabio}></img>
                <h3 className="card-title">Fabio </h3>
                  <p className="   color-gray">Founder & Project Manager <br></br> <a href="mailto:fabio.vasile@aoway.io">fabio.vasile@aoway.io</a></p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="mindmap-card">
              <div style={{ "textAlign": "center" }}>

                <img className='logoProfilo' src={imgAndrea}></img>
                <h3 className="card-title">Andrea </h3>
                  <p className="   color-gray">Founder <br></br> <a href="mailto:andrea.monetti@aoway.io">andrea.monetti@aoway.io</a></p>
                </div>
              </div>
            </div>
            
            <div className="col-lg-4">
              <div className="mindmap-card">
              <div style={{ "textAlign": "center" }}>

                <img className='logoProfilo' src={imgPaolo}></img>
                <h3 className="card-title">Paolo </h3>

                  <p className="   color-gray">CEO <br></br> <a href="mailto:paolo.monetti@aoway.io">paolo.monetti@aoway.io</a></p>
                </div>
              </div>

            </div>
          


          </div>


          <div className='row' style={{ "marginTop": "80px" }}>
        <div className='col-md-3 col-1'></div>
        <div className='col-md-6 col-10'>
          <h1 className="banner-title color-lightblue">FAQ</h1>
          <p className="banner-parag mt-3">Frequently asked questions</p>

        </div>
      </div>



      <div className='row' style={{"marginTop":"40px"}}>

        <div className='col-md-3'></div>
        <div className='col-md-6'>

 <Accordion defaultActiveKey="0" flush>
            <Accordion.Item eventKey="0">
              <Accordion.Header>What network is being used?</Accordion.Header>
              <Accordion.Body>
              Aoway develops its project on Polygon network
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Why did we choose MATIC?</Accordion.Header>
              <Accordion.Body>
              According to our studies, we consider the Polygon network to be robust with strong future potential. In addition 
                in the mint phase the GasFee are found to be negligible              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>What are the funds for?</Accordion.Header>
              <Accordion.Body>
              The funds raised will be used to implement the development of Aoway in order to 
              provide an increasingly comprehensive service to the community and to pay all contributors.              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>How to create a cryptocurrency portfolio?</Accordion.Header>
              <Accordion.Body>
                See <a href="https://academy.binance.com/it/articles/how-to-add-polygon-to-metamask" target="_blank">istruzioni configurazione Wallet</a>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>Can I get help with the creation of my virtual portfolio?</Accordion.Header>
              <Accordion.Body>
              Certainly, enter our discord server and we will follow you step by step.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>Can I start using the benefits today?</Accordion.Header>
              <Accordion.Body>
              Benefits can be used from the day after the cards are revealed
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>How long is this NFT valid for?</Accordion.Header>
              <Accordion.Body>
              Our NFTs will always be valid, they do not expire
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header>How do I get private access to communities?</Accordion.Header>
              <Accordion.Body>
              Following us on our socials and joining our Discord server
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="9">
              <Accordion.Header>Where can I purchase an NFT from Aoway? </Accordion.Header>
              <Accordion.Body>
              Follow our socials to find out the date of the Mint
              </Accordion.Body>
            </Accordion.Item>

          </Accordion>


          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Less and less is missing!</Modal.Title>
            </Modal.Header>
            <Modal.Body>Subscribe to our socials to be constantly updated on the Aoway universe <br></br><br></br>
              <a target="_blank" href="https://t.me/+LjZEAQKWqUxiN2Y8" style={{ "color": "#000", "fontSize": "25px" }}><i className="fa fa-paper-plane" aria-hidden="true"></i></a> &nbsp;
              <a target="_blank" href="https://www.facebook.com/aowayNFT/" style={{ "color": "#000", "fontSize": "25px" }}><i className="fa fa-facebook" aria-hidden="true"></i></a> &nbsp;
              <a target="_blank" href="https://www.instagram.com/aoway_nft/" style={{ "color": "#000", "fontSize": "25px" }}><i className="fa fa-instagram" aria-hidden="true"></i></a> &nbsp;
              <a target="_blank" href="https://twitter.com/AowayNft/" style={{ "color": "#000", "fontSize": "25px" }}><i className="fa fa-twitter" aria-hidden="true"></i></a> &nbsp;
              <a target="_blank" href='https://discord.gg/Qezt5fyjs7' style={{ "color": "#000", "fontSize": "25px" }}><img style={{ "width": "25px", "marginTop": "-12px" }} src="https://img.icons8.com/ios-glyphs/20/000000/discord-logo.png" /></a>

              <br></br><br></br>
              <p><b>Prepared in time!</b></p>
              <p><a href='https://academy.binance.com/it/articles/how-to-add-polygon-to-metamask' target="_blank">How to configure Metamask</a></p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>



          <Modal show={showProfile} onHide={handleCloseProfile}>
            <Modal.Header closeButton>
              <Modal.Title>Team</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Andrea and Fabio's passion for cryptocurrency and gaming gave birth to Aoway, a startup
                innovator that aims to use blockchain technology to bring companies and customers closer together.
                For the development of the project, Paolo and Pasquale were also involved so as to
                complete the team so that tasks could be divided by expertise.
                <br></br>
                The common thread that unites all members is to create a serious, transparent and useful project.
                <br></br>
                <br></br>

                - <b>Fabio</b>, founder and responsible for relations with external collaborators and developers, despite
                his young age (25 years old) has already embarked on entrepreneurial activity in the optical industry.
                <br></br>

                - <b>Andrea and Paolo</b>, Founder and CEO respectively, brothers aged 35 and 36, work together
                in the family business, which is developed in the tourism and catering sector. Andrea,
                a cryptocurrency aficionado, is in charge of the technical part of the project, while Paul follows the
                bureaucracy.
                <br></br>

                - <b>Pasquale</b>, young 32-year-old pharmacist as well as Fabio's trusted friend, who is also passionate
                of cryptocurrencies and showed a high interest in the project, thus deciding to finance
                the activity to enable its development</p>


            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleCloseProfile}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>




          <br></br>
          <br></br>
          <br></br>
        </div>
      </div>


      <div className="container-fluid footer-section px-5">
        <div className="row full-high-row">

          <div className="col-lg-4 center-content align-items-center">
            <img src={logofooter} />
          </div>
          <div className="col-lg-4 center-content flex-row">
            <div className="">
              <ul className="p-0">
                <li><a style={{ "color": "#000" }} href="mailto:info@aoway.io"><b>Contact us</b></a> </li>
              </ul>
            </div>
            <div className="link-container-center">
              <ul className="p-0">
              <li><a style={{ "color": "#000" }} href="mailto:business@aoway.io"><b>Partnership</b></a></li>
              </ul>
            </div>
            <div className="">
              <ul className="p-0">
                <li><a style={{ "color": "#000" }} target="_blank" href={wp}><b>Whitepaper</b></a></li>
              </ul>
            </div>

          </div>
          <div className="col-lg-4 center-content">
            <p>Follow us:</p>
            <p className="color-black social-icons">
              <a target="_blank" href="https://twitter.com/AowayNft/" style={{ "color": "#000" }}><i className="fa fa-twitter" aria-hidden="true"></i></a> &nbsp;
              <a target="_blank" href='https://discord.gg/Qezt5fyjs7' style={{ "color": "#000" }}><img src="https://assets-global.website-files.com/6257adef93867e50d84d30e2/636e0a6ac3c481f273141736_icon_clyde_black_RGB.png" /></a>&nbsp;
              <a target="_blank" href="https://t.me/+LjZEAQKWqUxiN2Y8" style={{ "color": "#000" }}><i className="fa fa-paper-plane" aria-hidden="true"></i></a> &nbsp;
              <a target="_blank" href="https://www.facebook.com/aowayNFT/" style={{ "color": "#000" }}><i className="fa fa-facebook" aria-hidden="true"></i></a> &nbsp;
              <a target="_blank" href="https://www.instagram.com/aoway_nft/" style={{ "color": "#000" }}><i className="fa fa-instagram" aria-hidden="true"></i></a> &nbsp;
            </p>
          </div>
        </div>
      </div>
      <p className="py-3 bg-gradient m-0 text-center color-white"><a href="#" style={{ "color": "#fff" }}>Privacy Policy</a>  -  <a href="#" style={{ "color": "#fff" }}>Cookie Policy</a></p>
    </div>
  )

}